import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@services/api.service';
import {Observable} from 'rxjs';
import {ActionType} from "@base/base.enums";

@Injectable({
  providedIn: 'root'
})
export class PpManagerService {

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private apiService: ApiService,
  ) {
  }

  getActivatedComponents(): Observable<any> {
    return this.apiService.get('activatedComponents');
  }

  getAnalyticsData(): Observable<any> {
    return this.apiService.get('analytics');
  }

  getDashboardData(parameters: any = {}): Observable<any> {
    return this.apiService.get('dashboard', parameters);
  }

  getUnreadNotifications(): Observable<any> {
    return this.apiService.get('notifications');
  }

  getNotification(itemIdentifier: string, createdAt: string): Observable<any> {
    let parameters = new HttpParams().set('itemIdentifier', itemIdentifier).set('createdAt', createdAt);
    return this.apiService.get('notifications', parameters);
  }

  getConfigurationData(): Observable<any> {
    return this.apiService.get('configurations')
  }

  getConfigurationDetailData(component: string): Observable<any> {
    return this.apiService.get(`configuration/${component}`);
  }

  getDashboardDetailData(component: string): Observable<any> {
    return this.apiService.get(`dashboard/${component}`);
  }

  getComponents(): Observable<any> {
    return this.apiService.get(`components`);
  }

  getComponentsData(component: string, filter: any = {}): Observable<any> {
    return this.apiService.get(`components/${component}`, filter);
  }

  postComponent(component: string, data: any): Observable<any> {
    return this.apiService.post(`components/${component}`, data);
  }

  patchComponent(component: string, data: any): Observable<any> {
    return this.apiService.patch(`components/${component}`, data);
  }

  deleteComponents(component: string, data: any): Observable<any> {
    return this.apiService.delete(`components/${component}`, data);
  }

  getComponentsConfigData(component: string, configId: string, configSubId: string = ''): Observable<any> {
    let configComposedId = configId;
    if (configSubId !== '') {
      configComposedId = configComposedId + ':' + configSubId;
    }
    return this.apiService.get(`components/${component}/${configComposedId}`);
  }

  getUsers(page: number = 1): Observable<any> {
    let parameters = new HttpParams().set('page', page);
    return this.apiService.get(`users`, parameters);
  }

  getUserGroups(): Observable<any> {
    return this.apiService.get(`users/groups`);
  }

  postUsers(request: any): Observable<any> {
    let port = document.location.port;
    request["loginUrl"] = document.location.protocol + '//' + document.location.hostname;
    if (port != undefined && port != "80") {
      request["loginUrl"] = request["loginUrl"] + ":" + port;
    }

    return this.apiService.post(`users`, request);
  }

  resetPassword(data: any): Observable<any> {
    data["action"] = "resetPassword"
    return this.apiService.patch(`users`, data);
  }

  enableUser(data: any): Observable<any> {
    data["action"] = "enable"
    return this.apiService.patch(`users`, data);
  }

  disableUser(data: any) {
    data["action"] = "disable"
    return this.apiService.patch(`users`, data);
  }

  getLogs(data: any = undefined) {
    //let parameters= new HttpParams().set('page', data);
    return this.apiService.get(`logs`, data);
  }

  postLogs(data: any) {
    //let parameters= new HttpParams().set('page', data);
    return  this.apiService.post(`logs`, data);
  }
  patchLogs(data: any) {
    return  this.apiService.patch(`logs`, data);
  }

  sftpGetFolderStructure(): Observable<any> {
    let parameters = new HttpParams().set("action", 'list_folders')
    let response = this.apiService.get(`components/sftp`, parameters);
    return response
  }

  sftpListDirectory(host: string, user: string, password: string, directory: string): Observable<any> {
    let parameters = new HttpParams().set('sftp_host', host).set('sftp_username', user).set('sftp_password', password).set('sftp_remote_dir', directory);
    return this.apiService.get("sftp_client/list", parameters);
  }

  sftpGetFiles(host: string, user: string, password: string, remoteFiles: Array<string>): Observable<any> {
    let parameters = new HttpParams().set('sftp_host', host).set('sftp_username', user).set('sftp_password', password).set('sftp_remote_file', remoteFiles[0]).set('sftp_remote_files', remoteFiles.join(', '));
    let endpoint = "sftp_client/get_bulk"
    if (remoteFiles.length == 1){
      parameters.set('sftp_remote_file', remoteFiles[0]);
      endpoint = "sftp_client/get"
     }
    else {
      parameters.set('sftp_remote_files', remoteFiles.join(', '));
    }
    return this.apiService.get(endpoint, parameters);
  }

  sftpAddDirectory(host: string, user: string, password: string, remoteDirectory: string): Observable<any>{
    let data = {'sftp_host': host, 'sftp_username': user, 'sftp_password': password, 'sftp_remote_folder': remoteDirectory};
    return this.apiService.put("sftp_client/put", data);
  }

  sftpUploadFile(host: string, user: string, password: string, file_name: string, file_content: string){
    let data = {'sftp_host': host, 'sftp_username': user, 'sftp_password': password, 'sftp_remote_file': file_name, 'sftp_file_content': file_content};
    return this.apiService.post("sftp_client/post", data);
  }

  sftpDeleteItems(host: string, user: string, password: string, item_names: Array<string>){
    let data = {'sftp_host': host, 'sftp_username': user, 'sftp_password': password, 'sftp_item': item_names[0], "sftp_items": item_names.join(", ")};
    if (item_names.length == 1){
      return this.apiService.delete("sftp_client/delete", data);

      }
    return this.apiService.delete("sftp_client/delete_bulk", data);
  }
}
