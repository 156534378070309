const region = 'eu-central-1';
const userPoolId = 'eu-central-1_7YTngWidE';
const userPoolWebClientId = '5nrga1rq0icb8oclgeaigfae6s';
const identityPoolId = 'eu-central-1:20d19acc-4da7-4da8-a116-748115fe90f2';

export const environment = {
  production: false,
  apiUrl: 'https://api.demo-partner-flex.powerdata.de/il-backend',
  clientName: 'demo-partner-flex',
  dashboardItemsCount: 2,
  sftpHost: 's-c161d1c9e82a461ba.server.transfer.eu-central-1.amazonaws.com',
  sftpPort: 22,
  AWS: {
    amplifyConfig : {
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        identityPoolId
      }
    }
  },
  translation: {
    default: 'en',
    languages: [
      { name: 'English', key: 'en' },
      { name: 'Deutsch', key: 'de' }
    ]
  }
};
